import { createI18n } from 'vue-i18n';

const locale = window.location.pathname.replace(/^\/([^/]+).*/i, '$1');

function decodeHtmlEntities(text: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');
  return doc.documentElement.textContent || '';
}

export const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,
  locale: locale.trim().length && locale != '/' ? locale : 'de',
  fallbackLocale: 'de',
  silentTranslationWarn: true,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlInMessage: false,
  postTranslation: (text) => {
    if (typeof text !== 'string') return text;
    return decodeHtmlEntities(text);
  },
});
